<template lang="pug">
  v-app
    dashboard-core-app-bar(v-model="expandOnHover")

    dashboard-core-drawer(:expand-on-hover.sync="expandOnHover")

    dashboard-core-view
</template>

<script>
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreView: () => import('./components/core/View'),
    },

    data: () => ({
      expandOnHover: true,
    }),
  }
</script>
